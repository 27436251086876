<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid class="fill-height">
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $t("banners.title") }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            @click="showAddItemDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $t("banners.add") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="banners"
            :showView="true"
            :showEdit="true"
            :showDelete="true"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="addItemDialog"
      persistent
      max-width="900px"
      v-model="addItemDialog"
    >
      <add-banner
        :originalItem="selectedItem"
        @success="itemAdded"
        @cancel="addItemDialog = false"
      />
    </v-dialog>

    <v-dialog
      hide-overlay
      fullscreen
      v-if="viewImageDialog"
      v-model="viewImageDialog"
    >
      <view-file
        :url="selectedItem.image.url"
        type="image"
        @cancel="viewImageDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="deleteVideoDialog"
      v-model="deleteVideoDialog"
    >
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteVideoDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import addBanner from "./add-banner";
import deleteItem from "@/components/delete";
import filesTable from "@/components/files-table";
import viewFile from "@/components/view-file";
import { mapActions, mapState } from "vuex";

export default {
  name: "Banners",
  components: {
    addBanner,
    deleteItem,
    filesTable,
    viewFile,
  },
  data() {
    return {
      banners: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addItemDialog: false,
      selectedItem: null,
      deleteVideoDialog: false,
      viewImageDialog: false,
      headers: [
        {
          value: "image",
          width: "150px",
        },
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.duration"),
          value: "bannerDuration",
        },
        {
          text: this.$t("headers.categories"),
          value: "visibleCategories",
          align: "center",
        },
        {
          text: this.$t("headers.subcategories"),
          value: "visibleSubcategories",
          align: "center",
        },
        {
          text: this.$t("headers.start_date"),
          value: "startDate",
          width: "200px",
        },
        {
          text: this.$t("headers.end_date"),
          value: "endDate",
          width: "200px",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          width: "200px",
          align: "end",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    handleMenuActions(payload) {
      switch (payload.action) {
        case "show_image":
          this.selectedItem = payload.item;
          this.viewImageDialog = true;
          break;
        case "edit":
          this.editItem(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
      }
    },
    showAddItemDialog() {
      this.selectedItem = null;
      this.addItemDialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.addItemDialog = true;
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteVideoDialog = true;
    },
    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("banners")
        .doc(this.selectedItem[".key"])
        .delete()
        .then(async (response) => {
          this.snackbarText = this.$t("banners.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteVideoDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },

    itemAdded() {
      this.addItemDialog = false;
      this.snackbarText = this.selectedItem
        ? this.$t("banners.updated")
        : this.$t("banners.added");
      this.snackbar = true;
    },
  },
  mounted() {
    this.cleanSearch();

    db.collection("banners")
      .where("groupId", "==", this.groupId)
      .onSnapshot((response) => {
        this.banners = [];
        response.forEach((item) => {
          let banner = item.data();
          banner[".key"] = item.id;
          this.banners.push(banner);
        });
        this.loading = false;
      });
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>