<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ originalItem ? $t('dialog.edit') : $t('dialog.add') }} banner</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-file-input
                accept="image/png, image/jpeg, image/gif"
                show-size
                :disabled="loading"
                @change="selectedFile"
                id="fileInput"
                label="Seleccionar imagen"
                class="image-picker "
                rounded
                filled
              ></v-file-input>

              <div class="image-container d-none  d-md-block">
                <img
                  contain
                  :style="
                    !src && banner.image && !banner.image.url
                      ? 'filter: grayscale(100%); border:1px solid #52525242'
                      : 'border:1px solid #52525242'
                  "
                  :src="currentImage"
                  class="preview"
                />
                <i @click="filePicker" class="fas fa-plus add"></i>
                <v-progress-linear
                  :value="progress"
                  color="progress-bar"
                  style="border-radius: 5px"
                  height="30"
                  class="mt-3"
                >
                  <strong>{{ Math.ceil(progress) }}%</strong>
                </v-progress-linear>
              </div>
            </v-col>

            <v-col cols="12" sm="8" md="8">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="banner.name"
                    :label="$t('dialog.name')"
                    hide-details
                    :loading="loading"
                    clearable
                    rounded
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model.number="banner.duration"
                    :label="$t('dialog.duration_seconds')"
                    :loading="loading"
                    clearable
                    type="number"
                    hide-details
                    rounded
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    :value="banner.description"
                    :label="$t('dialog.description')"
                    :loading="loading"
                    clearable
                    v-model="banner.description"
                    :rows="9"
                    hide-details
                    rounded
                    filled
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date[0]"
                        :label="$t('dialog.start_date')"
                        prepend-icon="fa-calendar-alt"
                        readonly
                        v-bind="attrs"
                        rounded
                        filled
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date[0]"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date[1]"
                        :label="$t('dialog.end_date')"
                        prepend-icon="fa-calendar-alt"
                        readonly
                        v-bind="attrs"
                        rounded
                        filled
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date[1]"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              style="min-height: 260px; overflow-y: auto"
              cols="12"
              sm="12"
              md="12"
            >
              <span class="mb-10 font-weight-bold">{{$t('banners.show_in')}}</span>
              <v-tabs v-model="tab">
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1"> {{$t('banners.main_screen')}} </v-tab>

                <v-tab href="#tab-2"> {{$t('banners.categories')}} </v-tab>

                <v-tab href="#tab-3"> {{$t('banners.subcategories')}} </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                  <v-checkbox
                    v-model="item.selected"
                    v-for="(item, index) of screens"
                    :key="index"
                    :label="item.name"
                  ></v-checkbox>
                </v-tab-item>

                <v-tab-item value="tab-2">
                  <v-checkbox
                    v-model="item.selected"
                    v-for="(item, index) of categories"
                    :key="index"
                    :label="item.name"
                  ></v-checkbox>
                </v-tab-item>

                <v-tab-item value="tab-3">
                  <v-checkbox
                    v-model="item.selected"
                    v-for="(item, index) of subcategories"
                    :key="index"
                    :label="item.name"
                  ></v-checkbox>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="6" sm="6" md="6">
              <v-switch v-model="banner.active" :label="$t('button.active')"></v-switch>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  class="mt-3"
                  @click="originalItem ? update() : save()"
                  color="buttons"
                  >{{$t('button.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "add-banner",
  props: ["originalItem"],
  data() {
    return {
      banner: {
        name: "",
        image: {
          url: "",
        },
        description: "",
        duration: 10,
        startDate: "",
        endDate: "",
        active: false,
      },
      snackbarText: "",
      snackbar: false,
      loading: true,
      date: [],
      tab: 0,
      file: "",
      menu1: false,
      menu2: false,
      src: "",
      items: [],
      screens: [],
      categories: [],
      subcategories: [],
      progress: 0,
      defaultImage: require("@/assets/wink-placeholder.png"),
    };
  },
  computed: {
    ...mapState(["user", "userDoc", "groupId"]),
    currentImage() {
      return this.src
        ? this.src
        : this.banner.image && this.banner.image.url
        ? this.banner.image.url
        : this.defaultImage;
    },
  },

  methods: {
    addCharacteristic() {
      this.product.characteristics.push({
        name: "",
        value: "",
      });
    },
    removeCharacteristic(index) {
      this.product.characteristics.splice(index, 1);
    },
    updateItems(id) {
      this.product.characteristics = [];
      let result = this.characteristics.find((item) => item[".key"] == id);
      result.items.forEach((item) => {
        this.product.characteristics.push({
          name: item,
          value: "",
        });
      });
    },

    addFile() {
      document.getElementById("fileInput").click();
    },
    selectedFile(e) {
      this.file = e;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.src = e.target.result;
      };
      reader.readAsDataURL(this.file);
    },

    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.date[0].split("-");
        this.banner.startDate = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("00"),
            parseInt("00"),
            parseInt("01")
          )
        );
        date = this.date[1].split("-");
        this.banner.endDate = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("23"),
            parseInt("59"),
            parseInt("00")
          )
        );

        resolve("success");
      });
    },

    validateBanner() {
      if (!this.banner.name) {
        this.snackbarText = this.$t('banners.insert_name');
        this.snackbar = true;
        return false;
      } else if (!this.banner.duration) {
        this.snackbarText = this.$t('banners.insert_duration')
        this.snackbar = true;
        return false;
      } else if (!this.date[0] || !this.date[1]) {
        this.snackbarText = this.$t('banners.insert_dates')
        this.snackbar = true;
        return false;
      } else if (!this.file && !this.originalItem) {
        this.snackbarText = this.$t('banners.select_image');
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
    filePicker() {
      document.getElementById("fileInput").click();
    },

    async save() {
      if (this.validateBanner()) {
        let visibleScreens = this.screens.filter((item) => item.selected);
        visibleScreens = visibleScreens.map((item) => {
          return item[".key"];
        });

        let visibleCategories = this.categories.filter((item) => item.selected);
        visibleCategories = visibleCategories.map((item) => {
          return item[".key"];
        });

        let visibleSubcategories = this.subcategories.filter(
          (item) => item.selected
        );
        visibleSubcategories = visibleSubcategories.map((item) => {
          return item[".key"];
        });

        await this.generateTimestamp();
        this.banner.createdAt = new Date();
        this.banner.createdBy = this.user.uid;
        this.banner.visibleScreens = visibleScreens;
        this.banner.visibleCategories = visibleCategories;
        this.banner.visibleSubcategories = visibleSubcategories;
        this.banner.groupId = this.groupId;
        this.loading = true;

        db.collection("banners")
          .add(this.banner)
          .then(async (ref) => {
            let filename = `${Date.now()}_${Math.floor(
              Math.random() * (10000000000 - 10000000) + 10000000
            )}_${Math.floor(
              Math.random() * (10000000000 - 10000000) + 10000000
            )}`;
            let url = await this.uploadImages(ref.id);
            await db.collection("banners").doc(ref.id).update({
              image: {
                filename,
                url,
              },
            });

            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              this.$tc('messages.unknown');
            this.snackbar = true;
          });
      }
    },

    async update() {
      if (this.validateBanner()) {
        let visibleScreens = this.screens.filter((item) => item.selected);
        visibleScreens = visibleScreens.map((item) => {
          return item[".key"];
        });

        let visibleCategories = this.categories.filter((item) => item.selected);
        visibleCategories = visibleCategories.map((item) => {
          return item[".key"];
        });

        let visibleSubcategories = this.subcategories.filter(
          (item) => item.selected
        );
        visibleSubcategories = visibleSubcategories.map((item) => {
          return item[".key"];
        });

        await this.generateTimestamp();
        this.loading = true;

        db.collection("banners")
          .doc(this.originalItem[".key"])
          .update({
            name: this.banner.name,
            duration: this.banner.duration,
            description: this.banner.description ? this.banner.description : "",
            startDate: this.banner.startDate,
            endDate: this.banner.endDate,
            modifiedAt: new Date(),
            modifiedBy: this.user.uid,
            visibleScreens,
            visibleCategories,
            visibleSubcategories,
            active: this.banner.active,
          })
          .then(async (ref) => {
            if (this.file) {
              let filename = `${Date.now()}_${Math.floor(
                Math.random() * (10000000000 - 10000000) + 10000000
              )}_${Math.floor(
                Math.random() * (10000000000 - 10000000) + 10000000
              )}`;
              let url = await this.uploadImages(this.originalItem[".key"]);
              await db
                .collection("banners")
                .doc(this.originalItem[".key"])
                .update({
                  image: {
                    filename,
                    url,
                  },
                });
            }

            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              this.$tc('messages.unknown');
            this.snackbar = true;
          });
      }
    },

    uploadImages(bannerId) {
      return new Promise((resolve, reject) => {
        let extension = this.file.type.split("/").pop();
        let dataName = `${bannerId}.${extension}`;

        var uploadTask = fb
          .app()
          .storage("gs://beanage-banners")
          .ref()
          .child(`${this.groupId}/${bannerId}/${dataName}`)
          .put(this.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.loading = false;
            this.snackbarText =
              this.$tc('messages.unknown');
            this.snackbar = true;
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    },
  },
  async mounted() {
    await this.$binding(
      "screens",
      db
        .collection("screens")
        .where("groupId", "==", this.groupId)
        .where("selectedApp", "==", "directories")
    );
    await this.$binding(
      "categories",
      db.collection("categories").where("groupId", "==", this.groupId)
    );

    for (let i = 0; i < this.categories.length; i++) {
      let category = this.categories[i];
      await db
        .collection(`categories/${category[".key"]}/subcategories`)
        .get()
        .then((response) => {
          response.forEach((item) => {
            this.subcategories.push({
              ".key": item.id,
              name: item.data().name,
            });
          });
        });
    }

    if (this.originalItem) {
      this.banner = Object.assign({}, this.originalItem);
      this.date = [
        moment(new Date(this.banner.startDate.seconds * 1000)).format(
          "YYYY-MM-DD"
        ),
        moment(new Date(this.banner.endDate.seconds * 1000)).format(
          "YYYY-MM-DD"
        ),
      ];

      this.screens.forEach((item) => {
        item.selected = this.banner.visibleScreens.includes(item[".key"]);
      });

      this.categories.forEach((item) => {
        item.selected = this.banner.visibleCategories.includes(item[".key"]);
      });

      this.subcategories.forEach((item) => {
        item.selected = this.banner.visibleSubcategories.includes(item[".key"]);
      });
    }

    this.loading = false;
  },
};
</script>

<style scoped>
.preview {
  width: 100%;
  max-height: 385px;
  min-height: 385px;
  object-fit: contain;
}

.image-container {
  position: relative;
  width: 100%;
  max-height: 385px;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

.image-picker {
  display: none;
}

@media only screen and (max-width: 600px) {
  .image-picker {
    display: flex;
  }
}
</style>

